import  React  from "react";
import styled from "styled-components"
import { BodyText, GrayText, HeaderText3, OutlineGrayAnchor } from "../elements/shared/texts"
import { PaddingContainer } from "../elements/shared/containers"
import  SectionHeader  from "../components/SectionHeader"
import { MotionLinkWrapper } from "../elements/shared/buttons"


const BgContainer = styled.div`
  color: ${props => props.theme.colors.primaryText};
  
  .detail {
    background: ${props => props.theme.colors.deepBackground};
    padding: 50px 20px;
    border-radius: 20px;
    border: 0.5px solid ${props => props.theme.colors.border};
    box-shadow: 0 30px 60px ${props => props.theme.colors.mainShadow};
    text-align: center;
    
  }
  
  h4, p{
    width: min(800px, 100%);
    margin: 0 auto;   
  }
  
  h4{
    font-size: 28px;
    font-weight: 300;
    line-height: 1.125em;
    letter-spacing: -0.01em;
  }
  
  p{
    font-size: 20px;
  }
  
  img{
    width: 30%
  }
  
  a {
    width: 200px;
    margin: 30px auto 0 auto;
  }
  
  .detail:nth-child(2){
    margin: min(100px, 20vw) 0;
  }
`


const WorkComponent = props => {

    return (

        <BgContainer id="work" >

          <PaddingContainer>

            <div className="container mx-auto ">
              {
                props.isHome &&  <SectionHeader title="Work" secondaryText="with us"/>
              }

            <div >

              {
                props.work.map( detail => (
                  <div className="detail" key={detail.type}>
                    <HeaderText3 isPrimary>{detail.type} </HeaderText3>
                    <div className={"flex mt-0"}>

                      <div>
                        <BodyText className="my-4" >
                          <h4> {detail.description}</h4>
                        </BodyText>
                        <GrayText >
                          <p>{detail.note}</p>
                        </GrayText>

                        <MotionLinkWrapper>
                        <OutlineGrayAnchor href={`mailto:contact@liquidcoder.com?subject=${detail.type}`}
                          > Contact us </OutlineGrayAnchor>
                        </MotionLinkWrapper>
                      </div>
                    </div>
                  </div>
                ))
              }

            </div>
            </div>
          </PaddingContainer>
        </BgContainer>

    )
}

export default WorkComponent