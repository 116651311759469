import  React from "react";
import { NakedPrimaryLink } from "../elements/shared/buttons"
import { H2, H3, HeaderText3, HeaderText ,HeaderText2,GrayTextWrapper } from "../elements/shared/texts"
import { BsArrowRight } from "react-icons/all"
import { motion} from "framer-motion"
import { UseGlobalStateContext } from "../context/globalContext"
import { getTheme } from "../themes/theme"
import styled from "styled-components"
import tw from "tailwind.macro"

const BackOverlayText = styled.div`
  ${tw`absolute top-0 left-0 z-0`};
  overflow: hidden;
  max-width: 100%;
  h1{
    background: none;
    color: gray;
    -webkit-text-fill-color: ${props => props.theme.colors.overlayBg};
  }
`

const SectionHeader = ({title, secondaryText, seeAll}) => {
  const { currentTheme } = UseGlobalStateContext()
  const theme = getTheme(currentTheme)

    return (
        <div className="flex justify-between items-end relative py-4 sm:py-6 md:py-8">
          <BackOverlayText>
            <HeaderText2>{title}</HeaderText2>
          </BackOverlayText>
          {
            title === "Work" ? 
            <div className={"z-10"}>
                <HeaderText3>{title}</HeaderText3>
                <GrayTextWrapper>
                  <H3>{secondaryText}</H3>
                </GrayTextWrapper>
            </div> : 
          <div className={"z-10"}>
              <GrayTextWrapper>
          <H3>{secondaryText}</H3>
              </GrayTextWrapper>
              <HeaderText3>{title}</HeaderText3>
          </div>
          }

          { 
            seeAll && 
            <motion.div className={"pb-2 z-10"}
                        whileHover={{
                          paddingLeft: 10,
                          paddingRight: 10,
                          paddingTop: 10,
                          paddingBottom: 10,
                          borderRadius: 15,
                          backgroundColor: theme.colors.lightPrimary,
                          y: -5,
  
                        }}
              >
              <NakedPrimaryLink to={ title === "Courses" ? "/courses" : "/blog" }>
                See all <BsArrowRight className={"inline"} size={20} />
              </NakedPrimaryLink>
            </motion.div>
          }

      </div>
    )
}

export default SectionHeader