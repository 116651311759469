import React from 'react';
import Layout from "../components/Layout"
import WorkComponent from "../components/WorkComponent"
import styled from "styled-components"
import { graphql } from "gatsby"
import ListHeader from "../components/ListHeader"


const Container = styled.div`
  margin: 0 auto;
`

const Work = ({ data }) => {

    return (
        <Layout>
          <ListHeader title={"Work with us"} />
            <Container>
                <WorkComponent work={data.contentfulHome.work} />
            </Container>
        </Layout>
    )
}

export const query = graphql`
    {
        contentfulHome {
            work {
                type
                description
                note
            }
        }
    }
`

export default Work

